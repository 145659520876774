@gray-base: #000000;
@gray-darker:            lighten(@gray-base, 13.5%); // #222
@gray-dark:              lighten(@gray-base, 20%);   // #333
@gray:                   lighten(@gray-base, 50%); // #555
@gray-light:             lighten(@gray-base, 65%); // #777
@gray-lighter:           lighten(@gray-base, 93.5%);//lighten(@gray-base, 93.5%); // #eee


@brand-primary: #ed1922;
@brand-secundary: #3cb664;
@brand-success:         #3cb664;
@brand-info:            #5bc0de;
@brand-warning:         #f0ad4e;
@brand-danger:          #e73f3b;

//@overheid : #3682a1;
//@zorg : #78bf31;
//@zakelijk : #6daece;
//@industrie : #ea6e37;
//@hrm : #a48fd9;
//@cloud : #51ceff;
//@powered : #2a3572;
//@groen : #71ae66;
//@default : #0295cb;


// Single primary color that all mixins use. Str8 ballin.
@primary:        hsl(131, 240%, 120%);

// Primary Variants -
@primaryLight:   hsl(hue(@primary), 100%, 70%);
@primaryDark:    hsl(hue(@primary), 60%, 40%);
@primaryFaded:   hsl(hue(@primary), 60%, 65%);

// 180degree Variants
@variant:         spin((@primary), 45);
@variantLight:    hsl(hue(@variant), 100%, 70%);
@variantDark:     hsl(hue(@variant), 60%, 40%);
@variantFaded:    hsl(hue(@variant), 60%, 65%);

// 90degree Variants
@corner:         spin((@primary), 90);
@cornerLight:    hsl(hue(@corner), 100%, 70%);
@cornerDark:     hsl(hue(@corner), 60%, 40%);
@cornerFaded:    hsl(hue(@corner), 60%, 65%);


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
@body-bg: #f9f9f9;
//** Global text color on `<body>`.
@text-color:            #7b7c7f;//#666;

//** Global textual link color.
@link-color:            @brand-primary;
//** Link hover color set via `darken()` function.
@link-hover-color:      lighten(@link-color, 15%);
//** Link hover decoration.
@link-hover-decoration: underline;


@font-family-sans-serif:  Helvetica, Arial, sans-serif;
@font-family-serif:       Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
@font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-sans-serif;

@font-size-base:          16px;
@font-size-large:         ceil((@font-size-base * 1.25)); // ~18px
@font-size-small:         ceil((@font-size-base * 0.85)); // ~12px

@font-size-h1:            floor((@font-size-base * 2.2)); // ~36px
@font-size-h2:            floor((@font-size-base * 2.0)); // ~30px
@font-size-h3:            floor((@font-size-base * 1.8)); // ~24px
@font-size-h4:            floor((@font-size-base * 1.4)); // ~18px
@font-size-h5:            @font-size-base;
@font-size-h6:            ceil((@font-size-base * 0.85)); // ~12px

@media (max-width: @screen-xs-max) {

  @font-size-h1:            floor((@font-size-base * 2.0)); // ~36px
  @font-size-h2:            floor((@font-size-base * 1.6)); // ~30px
  @font-size-h3:            floor((@font-size-base * 1.4)); // ~24px
  @font-size-h4:            floor((@font-size-base * 1.2)); // ~18px
  @font-size-h5:            @font-size-base;
  @font-size-h6:            ceil((@font-size-base * 0.85)); // ~12px

}
/* Small devices (tablets, 768px and up) */
@media (max-width: @screen-sm-max) {
  @font-size-h1:            floor((@font-size-base * 2.0)); // ~36px
  @font-size-h2:            floor((@font-size-base * 1.8)); // ~30px
  @font-size-h3:            floor((@font-size-base * 1.4)); // ~24px
  @font-size-h4:            floor((@font-size-base * 1.2)); // ~18px
  @font-size-h5:            @font-size-base;
  @font-size-h6:            ceil((@font-size-base * 0.85)); // ~12px
}




//** Unit-less `line-height` for use in components like buttons.
@line-height-base:        1.5;//1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
@line-height-computed:    floor((@font-size-base * @line-height-base)); // ~20px

@headings-font-family:    "Open Sans Condensed", Helvetica, Arial, sans-serif;
@headings-font-weight:    700;
@headings-line-height:    1.3;
@headings-color:          inherit;

@headings-small-color:        @gray;

@badge-bg:                    @gray;


//** Number of columns in the grid.
@grid-columns:              24;
//** Padding between columns. Gets divided in half for the left and right.
@grid-gutter-width:        20px;

@border-radius-base:        0px;
@border-radius-large:       0px;
@border-radius-small:       0px;
//@border-radius-base:        4px;
//@border-radius-large:       6px;
//@border-radius-small:       3px;

@screen-lg:                  1400px;
// Large screen / wide desktop
@container-large-desktop:      (1340px + @grid-gutter-width);

// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
@grid-float-breakpoint:     @screen-md-min;

//@navtop-height:                    40px;

@navbar-height:                    80px;
@navbar-margin-bottom:             0px;
@navbar-border-radius:             0px;//@border-radius-base;
@navbar-default-color:             #000;
@navbar-default-bg:                transparent;//rgba(255,255,255,0.5);//@gray-lighter;//#ffffff;//@brand-primary;
@navbar-default-border:            transparent;//darken(@navbar-default-bg, 6.5%);
@navbar-padding-horizontal:        floor((@grid-gutter-width / 2));
@navbar-padding-vertical:          ((@navbar-height - @line-height-computed) / 2);
@navbar-collapse-max-height:       340px;

@navbar-border-radius:             @border-radius-base;
@navbar-padding-horizontal:        floor((@grid-gutter-width / 2));
@navbar-padding-vertical:          ((@navbar-height - @line-height-computed) / 2);
@navbar-collapse-max-height:       340px;



// Navbar links
@navbar-default-link-color:                rgba(255,255,255,0.75);//darken(#ffffff,20%);
@navbar-default-link-hover-color:          #ffffff;
@navbar-default-link-hover-bg:             transparent;//@brand-primary;
@navbar-default-link-active-color:         @brand-primary;
@navbar-default-link-active-bg:            transparent;//lighten(@navbar-default-bg, 6.5%);
@navbar-default-link-disabled-color:       darken(@navbar-default-bg, 6.5%);
@navbar-default-link-disabled-bg:          transparent;

// Navbar brand label
@navbar-default-brand-color:               @navbar-default-link-color;
@navbar-default-brand-hover-color:         darken(@navbar-default-brand-color, 10%);
@navbar-default-brand-hover-bg:            transparent;

// Navbar toggle
@navbar-default-toggle-hover-bg:           lighten(@navbar-default-bg, 6.5%);
@navbar-default-toggle-icon-bar-bg:        darken(@navbar-default-bg, 6.5%);
@navbar-default-toggle-border-color:       #fff;


//=== Inverted navbar
// Reset inverted navbar basics
@navbar-inverse-color:                      #ccc;
@navbar-inverse-bg:                         transparent;//@brand-primary;
@navbar-inverse-border:                     transparent;//darken(@navbar-inverse-bg, 10%);

// Inverted navbar links
@navbar-inverse-link-color:                 lighten(@brand-primary,30%);//#ccc;
@navbar-inverse-link-hover-color:           #fff;
@navbar-inverse-link-hover-bg:              lighten(@brand-primary,3%);//darken(@navbar-inverse-bg, 12%);
@navbar-inverse-link-active-color:          @navbar-inverse-link-hover-color;
@navbar-inverse-link-active-bg:             darken(@navbar-inverse-bg, 12%);
@navbar-inverse-link-disabled-color:        #ccc;
@navbar-inverse-link-disabled-bg:           transparent;

// Inverted navbar brand label
@navbar-inverse-brand-color:                @navbar-inverse-link-color;
@navbar-inverse-brand-hover-color:          #fff;
@navbar-inverse-brand-hover-bg:             none;

// Inverted navbar toggle
@navbar-inverse-toggle-hover-bg:            darken(@navbar-inverse-bg, 12%);
@navbar-inverse-toggle-icon-bar-bg:         #fff;
@navbar-inverse-toggle-border-color:        darken(@navbar-inverse-bg, 12%);


//== Thumbnails
//
//##

//** Padding around the thumbnail image
@thumbnail-padding:           4px;
//** Thumbnail background color
@thumbnail-bg:                #fff;//@body-bg;
//** Thumbnail border color
@thumbnail-border:            #ddd;
//** Thumbnail border radius
@thumbnail-border-radius:     @border-radius-base;

//** Custom text color for thumbnail captions
@thumbnail-caption-color:     @text-color;
//** Padding around the thumbnail caption
@thumbnail-caption-padding:   9px;


@dropdown-bg:                    @navbar-default-bg;
@dropdown-border:                none;
@dropdown-fallback-border:       none;
@dropdown-divider-bg:            lighten(@navbar-default-bg,10%);

@dropdown-link-color:            @navbar-default-link-color;
@dropdown-link-hover-color:      @navbar-default-link-hover-color;
@dropdown-link-hover-bg:         lighten(@navbar-default-bg,10%);;

@dropdown-link-active-color:     @component-active-color;
@dropdown-link-active-bg:        @component-active-bg;

@dropdown-link-disabled-color:   @gray-light;

@dropdown-header-color:          @gray-light;

@dropdown-caret-color:           #000;


//== Labels
//
//##

//** Default label background color
@label-default-bg:            darken(@gray-light, 10%);
//** Primary label background color
@label-primary-bg:            @brand-primary;
//** Success label background color
@label-success-bg:            @brand-success;
//** Info label background color
@label-info-bg:               @brand-info;
//** Warning label background color
@label-warning-bg:            @brand-warning;
//** Danger label background color
@label-danger-bg:             @brand-danger;

//** Default label text color
@label-color:                 #fff;
//** Default text color of a linked label
@label-link-hover-color:      #fff;

//== Thumbnails
//
//##

//** Padding around the thumbnail image
@thumbnail-padding:           4px;
//** Thumbnail background color
@thumbnail-bg:                #fff;//@body-bg;
//** Thumbnail border color
@thumbnail-border:            #ddd;
//** Thumbnail border radius
@thumbnail-border-radius:     @border-radius-base;

//** Custom text color for thumbnail captions
@thumbnail-caption-color:     @text-color;
//** Padding around the thumbnail caption
@thumbnail-caption-padding:   9px;


//== Wells
//
//##

@well-bg:                     @gray-lighter;//bg#f5f5f5;
@well-border:                 darken(@well-bg, 7%);


//== Carousel
//
//##

@carousel-text-shadow:                        0 1px 2px rgba(0,0,0,.8);

@carousel-control-color:                      #fff;
@carousel-control-width:                      15%;
@carousel-control-opacity:                    .5;
@carousel-control-font-size:                  20px;

@carousel-indicator-active-bg:                #fff;
@carousel-indicator-border-color:             #fff;

@carousel-caption-color:                      #fff;


//== Panels
//
//##

@panel-bg:                    #fff;
@panel-body-padding:          15px;
@panel-heading-padding:       10px 15px;
@panel-footer-padding:        @panel-heading-padding;
@panel-border-radius:         @border-radius-base;

//** Border color for elements within panels
@panel-inner-border:          #fff;//#ddd;
@panel-footer-bg:             #f5f5f5;

@panel-default-text:          @gray-dark;
@panel-default-border:        #ddd;
@panel-default-heading-bg:    #f5f5f5;

@panel-primary-text:          #fff;
@panel-primary-border:        @brand-primary;
@panel-primary-heading-bg:    @brand-primary;

@panel-success-text:          @state-success-text;
@panel-success-border:        @state-success-border;
@panel-success-heading-bg:    @state-success-bg;

@panel-info-text:             @state-info-text;
@panel-info-border:           @state-info-border;
@panel-info-heading-bg:       @state-info-bg;

@panel-warning-text:          @state-warning-text;
@panel-warning-border:        @state-warning-border;
@panel-warning-heading-bg:    @state-warning-bg;

@panel-danger-text:           @state-danger-text;
@panel-danger-border:         @state-danger-border;
@panel-danger-heading-bg:     @state-danger-bg;


/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
}