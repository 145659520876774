.flash-message {
  z-index           : 100000;
  position          : absolute;
  top               : 65px;
  width             : 80%;
  max-width         : 80%;
  min-width         : 60%;
  left              : 50%;
  -webkit-transform : translateX(-50%);
  transform         : translateX(-50%);
  & .alert {
    border      : 3px solid @brand-primary;
    line-height : 1;
    & *:first-child {
      margin-top : 0px;
    }
    & *:last-child {
      margin-bottom : 0px;
    }
    & h3 {
      font-size   : @font-size-h4;
      line-height : 1.2;
      margin      : 0;
    }
    & p {
      font-size   : @font-size-base;
      line-height : 1;
    }
  }
}

// Page header
// -------------------------

//.page-header {
//  padding-bottom: ((@line-height-computed / 2) - 1);
//  margin: (@line-height-computed * 2) 0 @line-height-computed;
//  border-bottom: 1px solid @page-header-border-color;
//}

.page-header {
  display          : block;
  margin           : -40px 0 15px 0;
  padding          : 15px;
  position         : relative;
  border           : none;
  background-color : @brand-secundary;
  border:2px solid #fff;
  & h1 {
    margin         : 0;
    padding        : 0;
    text-transform : capitalize;
    //background-color: yellow;
    color          : #fff; //lighten(@text-color,20%);
    //height         : 1;
    //line-height    : 1;
    border         : none;
  }
  & h2 {
    margin      : 0;
    padding     : 0;
    font-size   : @font-size-h2*0.8;
    color       : #fff;
    height      : 30px;
    line-height : 30px;
  }
}

//.triggers {
//  .bg();
//  .bg-lighter();
//  & > div > div > a {
//    display : block;
//    .text-center();
//    .padded();
//    .padded-lg();
//    &:hover {
//      background-color : darken(@gray-lighter, 2%);
//      color            : darken(@link-color, 5%);
//    }
//  }
//  & > div > div > div {
//    .text-center();
//    .padded();
//    .padded-lg();
//  }
//}

.content {


  p {
    a:not(*) {
      color : @brand-primary;
      //font-weight : bold;
      &:hover {
        color : lighten(@brand-primary, 10%)
      }
    }
  }
  strong {
    // color : lighten(@text-color, 10%);
  }

  .img-margin {
    margin    : @line-height-computed @line-height-computed;
    max-width : 95%;
  }

  // Simple ordered list styling
  & ol:not(.pagination):not(.list):not(.navbar-nav):not(.breadcrumb) {
    list-style-type : none;
    list-style-type : decimal !ie; /*IE 7- hack*/

    margin          : 30px 0 30px 0;
    margin-left     : 2.5em;
    padding         : 0;

    counter-reset   : li-counter;
    & li {
      position      : relative;
      margin-bottom : 20px;
      padding-left  : 0.5em;
      min-height    : 3em;
      border-left   : 2px solid #cccccc;
      &:before {
        position          : absolute;
        top               : 0;
        left              : -1.5em;
        width             : 0.8em;

        font-size         : 2em;
        line-height       : 1;
        font-weight       : bold;
        text-align        : right;
        color             : @brand-primary;

        content           : counter(li-counter);
        counter-increment : li-counter;
      }
    }
  }

  & ul:not(.pagination):not(.list):not(.navbar-nav):not(.accordion):not(.list-group):not(.summary):not(.accordion):not(.nav-pills) {
    list-style-type : none;
    padding-left    : 0px;
    margin          : 20px 0 20px 20px; // 0 0 15px;
    & li {
      margin       : 4px 0px;
      padding-left : 10px;
      &:before {
        content     : '\f105';
        font-family : 'FontAwesome';
        color       : @brand-primary;
        float       : left;
        margin      : 0 0 0 -15px;
        font-weight : 600;
      }
    }
  }
  & ul:not(.pagination):not(.list):not(.navbar-nav):not(.accordion):not(.list-group):not(.summary):not(.accordion).check {
    margin : 15px 0 15px 10px; // 0 0 15px;
    & li {
      font-size : @font-size-base;
      margin    : 10px 0;
      &:before {
        font-family : 'FontAwesome';
        font-size   : @font-size-base*0.9;
        content     : '\f00c';
        color       : @brand-primary;
        margin      : 0px 0 0 -20px;
      }
    }
    &.secundary {
      & li {
        &:before {
          color : @brand-secundary;
        }
      }
    }
  }
  & ul:not(.pagination):not(.list):not(.navbar-nav):not(.accordion):not(.list-group):not(.summary):not(.accordion).download {
    margin  : 5px 0;
    padding : 0;
    & li {
      margin          : 5px 0;
      padding-left    : 1.2em;
      list-style-type : none;
      //font-size       : @font-size-base;
      &:before {
        font-family : 'FontAwesome';
        //font-size : @font-size-base;
        content     : '\f019';
        color       : inherit;
        width       : 1.2em;
        margin      : 0px 0 0 -1.2em;
      }
      & a {
        font-weight : normal;
      }
    }
    &.secundary {
      & li {
        &:before {
          color : @brand-secundary;
        }
      }
    }
  }
  & ul:not(.pagination):not(.list):not(.navbar-nav):not(.accordion):not(.list-group).summary {
    margin  : 0;
    padding : 0;
    & li {
      margin          : 0;
      padding-left    : 0.8em;
      list-style-type : none;
      font-size       : @font-size-base;
      &:before {
        font-family : 'FontAwesome';
        font-size   : @font-size-base;
        content     : '\f105';
        color       : @brand-primary;
        width       : 0.8em;
        margin      : 0px 0 0 -0.8em;
      }
      & a {
        font-weight : normal;
      }
    }
    &.secundary {
      & li {
        &:before {
          color : @brand-secundary;
        }
      }
    }
  }

  & ul.accordion {

    margin  : 0;
    padding : 0;
    & li {
      display         : block;
      margin          : 0 0 5px 0;
      padding         : 0;
      font-size       : @font-size-base;
      list-style-type : none;
      & a {
        display          : block;
        padding          : @padding-large-vertical @padding-large-horizontal;
        background-color : @gray-lighter;
        text-transform   : uppercase;
        font-weight      : 500;
        &:after {
          content     : '\f105';
          font-family : 'FontAwesome';
          color       : @brand-primary;
          float       : right;
          margin      : 0 0 0 0px;
          font-weight : 600;
        }
      }
      & ul.panel-collapse {
        margin        : 0 0 5px 0 !important;
        padding       : 0 !important;
        border-left   : 2px solid @gray-lighter;
        border-bottom : 2px solid @gray-lighter;
        border-right  : 2px solid @gray-lighter;

        & li {
          display         : block;
          //background-color: green;
          margin          : 0 !important;
          padding         : @padding-large-vertical*2 @padding-large-horizontal !important;
          font-size       : @font-size-base;
          list-style-type : none !important;
          &:before {
            content : '' !important;
            margin  : 0px;
          }
        }
      }
    }

  }
  form.form-horizontal {
    & span.help-block {
      .small();
    }
  }
  & .youtube, &.vimeo {
    &.thumbnail {
      background-color : #fff;
      border           : none;
      color            : #fff;
      margin           : @grid-gutter-width 0;
      & h3 {
        line-height : 1.2;
        margin      : 4px 0;
        & i {
          color : @brand-primary;
        }
      }
    }
  }

  & a.thumbnail {
    &:hover {
      //box-shadow : 0 0 8px rgba(0, 0, 0, 0.2);
    }
  }

  & .tabpanel {
    //display          : block;
    .clearfix();
    //.padded();
    margin : 30px 0 40px;
    //background-color : yellow;
    //border : 1px solid @gray-light;
    & h3 {
      line-height : 1;
      padding     : 0 0 25px 15px;
      margin      : 15px 0 0 0;
    }
    & ul {
      //border-left     : 3px solid @brand-primary;
      list-style-type : none;
      & li {
        & a {
          padding        : 10px 8px 10px 25px;
          text-transform : uppercase;
        }
        &:hover a {
          background-color : transparent;
          color            : @brand-primary;
          font-weight      : bold;
        }
        &.active a {
          background-color : #e3eef7;
          color            : @brand-primary;
          font-weight      : bold;
          & i {
            font-weight : bold;

          }
          &:after {
            font-family : FontAwesome;
            content     : "\f061";
            position    : absolute;
            right       : 10px;
            color       : @brand-primary;
          }
        }
      }
    }
    & .tab-content {
      background-color : #e3eef7;
      padding          : 30px 30px;
      & h3 {
        padding : 0 0 25px 0;
      }
      & blockquote {
        background-color : transparent;
        padding          : 0 0 @line-height-computed*1;
        margin           : 0 0 @line-height-computed/2 0;
        font-size        : @font-size-base * 1.4;
        line-height      : 1.3;
        &:first-child {
          margin : 0 0 @line-height-computed/2;
        }
      }

    }
  }

}

.tags {
  & a {
    display : inline-block;
    margin  : 0 0 4px 0;
    & span.label {
      margin : 0;
    }
    &:hover {
      & span.label {
        background-color : lighten(@label-default-bg, 10%);
      }
    }
  }
}

.bordered {
  border-top    : 1px solid silver;
  border-bottom : 1px solid silver;
}

