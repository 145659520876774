.swiper-container {
  height : 400px; // old browsers
  height : 70vh; // overruled by style
  & .swiper-slide {
    -webkit-background-size : cover;
    background-size         : cover;
    background-position     : center;
    & .caption {
      position         : absolute;
      left             : 40px;
      bottom           : 40px;
      width            : 50%;
      max-width        : 50%;
      background-color : rgba(0, 0, 0, 0.40);
      color            : white;
      padding          : 16px 20px;
      font-size        : @font-size-base*0.9;
      line-height      : @line-height-base*0.8;
      text-shadow      : @carousel-text-shadow;
      & *:first-child {
        margin-top : 0px;
      }
      & *:last-child {
        margin-bottom : 0px;
      }
      & h1, h2, h3, h4, h5 {
        color : #fff;//@brand-primary;
      }
      & h1 {
        font-size : @font-size-h1*0.8;
      }
      & h2 {
        font-size : @font-size-h2*0.8;
      }
      & h3 {
        font-size : @font-size-h3*0.8;
      }
      & h4 {
        font-size : @font-size-h4*0.8;
      }
    }
  }
  & .swiper-pagination {
    & .swiper-pagination-bullet {
      background-color : transparent;
      opacity          : 0.5;
      width            : 15px;
      height           : 15px;
      border           : 1px solid #fff;
    }
    & .swiper-pagination-bullet-active {
      background-color : @brand-primary;
      opacity          : 1;
    }
  }

  &.inpage {
    height : auto;
    & .swiper-slide {
      background-color : #e3eef7;
      color            : @text-color;
      font-family      : "Lato", Helvetica, Arial, sans-serif;
      font-size        : @font-size-base;
      //padding: @padding*1.5 @padding*2;
    }
  }
}

.fullbackground {
  position                : relative;
  -webkit-background-size : cover;
  background-size         : cover;
  background-position     : center;
  & .caption {
    position         : absolute;
    left             : 40px;
    bottom           : 40px;
    width            : 50%;
    max-width        : 50%;
    background-color : rgba(0, 0, 0, 0.40);
    color            : white;
    padding          : 16px 20px;
    font-size        : @font-size-base*0.9;
    line-height      : @line-height-base*0.8;
    text-shadow      : @carousel-text-shadow;
    & *:first-child {
      margin-top : 0px;
    }
    & *:last-child {
      margin-bottom : 0px;
    }
    & h1 {
      font-size : @font-size-h1*0.8;
    }
    & h2 {
      font-size : @font-size-h2*0.8;
    }
    & h3 {
      font-size : @font-size-h3*0.8;
    }
    & h4 {
      font-size : @font-size-h4*0.8;
    }
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width : @screen-lg-min) {
  .swiper-container {
    & .swiper-slide {
      & .caption {
        left        : 60px;
        bottom      : 60px;
        width       : 40%;
        max-width   : 40%;
        font-size   : @font-size-base;
        line-height : @line-height-base*0.9;
        & *:first-child {
          margin-top : 0px;
        }
        & *:last-child {
          margin-bottom : 0px;
        }
        & h1 {
          font-size : @font-size-h1;
        }
        & h2 {
          font-size : @font-size-h2;
        }
        & h3 {
          font-size : @font-size-h3;
        }
        & h4 {
          font-size : @font-size-h4;
        }
      }
    }
  }

  .fullbackground {
    & .caption {
      left        : 60px;
      bottom      : 60px;
      width       : 40%;
      max-width   : 40%;
      font-size   : @font-size-base;
      line-height : @line-height-base*0.9;
      & *:first-child {
        margin-top : 0px;
      }
      & *:last-child {
        margin-bottom : 0px;
      }
      & h1 {
        font-size : @font-size-h1;
      }
      & h2 {
        font-size : @font-size-h2;
      }
      & h3 {
        font-size : @font-size-h3;
      }
      & h4 {
        font-size : @font-size-h4;
      }
    }
  }
}
