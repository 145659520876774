
.gap {
  display : block;
  @gap-height : 10px;
  height  : @gap-height;
  &.gap-sm {
    height : 5px;
  }
  &.gap-md {
    height : 10px;
  }
  &.gap-lg {
    height : 20px;
  }
  &.gap-xl {
    height : 40px;
  }
  &.gap-noswiper {
    height : 110px;
  }
}

//@padding : 10px;

.padded {
  padding : 20px;
  &.padded-xs {
    padding : 5px;
  }
  &.padded-sm {
    padding : 10px;
  }
  &.padded-lg {
    padding : 30px;
  }
  &.padded-onepage {
    padding : 30px 15px;
  }
  & *:first-child:not(.thumbnail) {
    margin-top : 0px;
  }
  & *:last-child:not(.thumbnail) {
    margin-bottom : 0px;
  }
}

.well {
  min-height       : 20px;
  padding          : 20px;
  margin-bottom    : 20px;
  background-color : @well-bg;
  border           : none; //1px solid @well-border;
  box-shadow       : none; //.box-shadow(inset 0 1px 1px rgba(0,0,0,.05));
  ////border-radius: @border-radius-base;
  blockquote {
    border-color : #ddd;
    border-color : rgba(0, 0, 0, .15);
  }
  & *:first-child:not(.thumbnail) {
    margin-top : 0px;
  }
  & *:last-child:not(.thumbnail) {
    margin-bottom : 0px;
  }
}

// Sizes
.well-lg {
  padding       : 24px;
  border-radius : @border-radius-large;
}

.well-sm {
  padding       : 9px;
  border-radius : @border-radius-small;
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width : @screen-lg-min) {
  .padded {
    padding : 30px;
    &.padded-xs {
      padding : 10px;
    }
    &.padded-sm {
      padding : 14px;
    }
    &.padded-lg {
      padding : 45px;
    }
    &.padded-onepage {
      padding : 45px 22px;
    }
  }
  .well {
    padding    : 30px;
  }
}


/* Small devices (tablets, 768px and up) */
@media (min-width : @screen-sm-min) {
}

/* Medium devices (desktops, 992px and up) */
@media (min-width : @screen-md-min) {
}

//.padded-home {
//  padding : @padding-vertical*2 @padding-horizontal*2;
//}

//.padded-content {
//  padding : 30px;
//  &.padded-sm {
//    padding : 20px;
//  }
//  &.padded-lg {
//    padding : 40px;
//  }
//}

.notpadded, .nopadding {
  padding : 0 0 !important;
}

.tooltip-inner {
  text-align : left;
}

& span.glossary {
  text-decoration : none;
  border-bottom   : 1px dotted;
  //border-color:darken(currentColor,30%);
  &:hover {
    //background-color: lighten(@brand-primary,10%);
    border-bottom : 1px solid;
    cursor        : pointer;
  }
}

.bg {
  background-color        : transparent;
  -webkit-background-size : cover;
  -moz-background-size    : cover;
  -o-background-size      : cover;
  background-size         : cover;
  background-position     : center center;

  &.bg-white, &.bg-default {
    background-color : #ffffff;
    color            : @text-color;
    & h1, h2, h3, h4, h5, h6 {
      color : inherit;
    }
    &.bg-border {
      border : 1px solid @gray-lighter;
    }
  }
  &.bg-transparent {
    background-color : transparent;
    color            : @text-color;
    & h1, h2, h3, h4, h5, h6 {
      color : inherit;
    }
    &.bg-border {
      border : 1px solid @gray-light;
    }
  }

  &.bg-transparent {
    background-color : transparent;
    color            : @text-color;
    & h1, h2, h3, h4, h5, h6 {
      color : inherit;
    }
    &.gradient {
      #gradient > .vertical-three-colors(lighten(@gray-light, 20%); lighten(@gray-light, 5%); 5%; @gray-light);
    }
  }

  &.bg-lighter {
    background-color : @gray-lighter;
    color            : @text-color;
  }

  &.bg-light {
    background-color : @gray-light;
    color            : @text-color;
    & a:not(.btn) {
      color : @brand-primary;
      &:hover {
        color : darken(@brand-primary, 20%);
      }
    }
    & h1, h2, h3, h4, h5, h6 {
      color : inherit;
    }
  }

  &.bg-medium, & a:not(.btn).bg-medium {
    background-color : @gray;
    color            : rgba(255, 255, 255, 0.8);
    & a:not(.btn) {
      color : rgba(255, 255, 255, 0.6);
      &:hover {
        color : rgba(255, 255, 255, 1);
      }
    }
    & h1, h2, h3, h4, h5, h6 {
      color : inherit;
    }
  }

  &.bg-dark, & a:not(.btn).bg-dark {
    background-color : @gray-dark;
    color            : rgba(255, 255, 255, 0.7);
    & a:not(.btn) {
      color : rgba(255, 255, 255, 0.8);
      &:hover {
        color : rgba(255, 255, 255, 1);
      }
    }
    & h1, h2, h3, h4, h5, h6 {
      color : inherit;
    }
  }
  &.bg-darker, & a:not(.btn).bg-darker {
    background-color : @gray-darker;
    color            : rgba(255, 255, 255, 1);
    & a:not(.btn) {
      color : rgba(255, 255, 255, 0.8) !important;
      &:hover {
        color : rgba(255, 255, 255, 1) !important;
      }
    }
    & h1, h2, h3, h4, h5, h6 {
      color : inherit;
    }
  }
  &.bg-black, & a:not(.btn).bg-black {
    background-color : darken(@gray-darker, 20%);
    color            : rgba(255, 255, 255, 1);
    & a:not(.btn) {
      color : rgba(255, 255, 255, 0.8);
      &:hover {
        color : rgba(255, 255, 255, 1);
      }
    }
    & h1, h2, h3, h4, h5, h6 {
      color : inherit;
    }
  }

  &.bg-primary {
    background-color : @brand-primary;
    color            : rgba(255, 255, 255, 0.9);
    & a:not(.btn) {
      color : rgba(255, 255, 255, 0.7);
      &:hover {
        color : rgba(255, 255, 255, 1);
      }
    }
    & h1, h2, h3, h4, h5, h6 {
      color : inherit;
    }
    &.light {
      background-color : #d5e5f3;
      color            : rgba(0, 0, 0, 0.9);
      & a:not(.btn) {
        color : rgba(0, 0, 0, 0.5);
        &:hover {
          color : rgba(0, 0, 0, 1);
        }
      }
    }
    &.lighter {
      background-color : #e3eef7;
      color            : rgba(0, 0, 0, 0.9);
      & a:not(.btn) {
        color : rgba(0, 0, 0, 0.5);
        &:hover {
          color : rgba(0, 0, 0, 1);
        }
      }
    }
  }

  &.bg-secundary {
    background-color : @brand-secundary;
    color            : rgba(255, 255, 255, 0.9);
    & a:not(.btn) {
      color : rgba(255, 255, 255, 0.7);
      &:hover {
        color : rgba(255, 255, 255, 1);
      }
    }
    & h1, h2, h3, h4, h5, h6 {
      color : inherit;
    }
    &.light {
      background-color : lighten(@brand-secundary, 30%);
      color            : rgba(255, 255, 255, 0.9);
    }
  }

  &.bg-success {
    background-color : @brand-success;
    color            : rgba(255, 255, 255, 0.8);
    & h1, h2, h3, h4, h5, h6 {
      color : inherit;
    }
    a {
      font-weight : 600;
      color       : lighten(@brand-success, 40%);
      &:hover {
        color : lighten(@brand-success, 50%);
      }
    }
  }
  &.bg-danger {
    background-color : @brand-danger;
    color            : rgba(255, 255, 255, 0.8);
    & h1, h2, h3, h4, h5, h6 {
      color : inherit;
    }
    a {
      font-weight : 600;
      color       : lighten(@brand-danger, 40%);
      &:hover {
        color : lighten(@brand-danger, 50%);
      }
    }
  }
}

